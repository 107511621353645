"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spinner = void 0;

var _Circles = require("./Circles");

var _Watch = require("./Watch");

var _Audio = require("./Audio");

var _BallTriangle = require("./BallTriangle");

var _Bars = require("./Bars");

var _CradleLoader = require("./CradleLoader");

var _Grid = require("./Grid");

var _Hearts = require("./Hearts");

var _MutatingDots = require("./MutatingDots");

var _Oval = require("./Oval");

var _Plane = require("./Plane");

var _Puff = require("./Puff");

var _RevolvingDot = require("./RevolvingDot");

var _Rings = require("./Rings");

var _TailSpin = require("./TailSpin");

var _ThreeDots = require("./ThreeDots");

var _Triangle = require("./Triangle");

var Spinner = {
  Circles: _Circles.Circles,
  Audio: _Audio.Audio,
  BallTriangle: _BallTriangle.BallTriangle,
  Bars: _Bars.Bars,
  CradleLoader: _CradleLoader.CradleLoader,
  Grid: _Grid.Grid,
  Hearts: _Hearts.Hearts,
  MutatingDots: _MutatingDots.MutatingDots,
  Oval: _Oval.Oval,
  Plane: _Plane.Plane,
  Puff: _Puff.Puff,
  RevolvingDot: _RevolvingDot.RevolvingDot,
  Rings: _Rings.Rings,
  TailSpin: _TailSpin.TailSpin,
  ThreeDots: _ThreeDots.ThreeDots,
  Triangle: _Triangle.Triangle,
  Watch: _Watch.Watch
};
exports.Spinner = Spinner;